import React from 'react'
import { Button } from '../components/Button'
import { appInsights } from '../appInsights'
import { contactEmail } from '../utils'

export const Contact = () => (
  <section id="contact" data-scrollspy className="bg-gradient rounded-lg flex-[4] flex items-center opacity-95">
    <div className="p-6 lg:p-12">
      <h2 className="text-[22px] leading-7 sm:text-2xl font-bold text-white">Want to know more?</h2>
      <h2 className="text-[22px] leading-7  sm:text-2xl font-bold text-black mb-6">We'd love to chat.</h2>
      <div>
        <Button onClick={() => {
          appInsights.trackEvent({ name: 'Contact Button Pressed' }, {});
          window.location.href = `mailto:${contactEmail}?subject=I'd like to know more about MOSAIC`
        }}>
            Contact us
        </Button> 
      </div>
    </div>
  </section>
)