import React from 'react'

const IconListItem = ({ icon, name, title, subtitle }) => (
  <div className="flex space-x-4 items-center">
    <img src={icon} alt={name} />
    <div>
      <h3 className={`${subtitle ? 'text-purple text-sm' : 'text-white text-lg'} font-bold mb-0.5`}>{title}</h3>
      { subtitle && <p className="text-sm text-gray-800 leading-4">{subtitle}</p> }
    </div>
  </div>
)

export default IconListItem