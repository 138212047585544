export const StatCard = ({ stat, icon, description, bgColor }) => (
  <div className={`rounded-lg flex flex-col items-center justify-center ${bgColor} px-4 py-7`}>
    <div className="text-6xl font-bold text-white mb-3">{stat}</div>
    <div className="flex flex-row justify-center items-center">
      <div className="mr-2 hidden sm:block">
        <img src={icon} alt="icon" className="w-7 max-h-8" />
      </div>
      <div className="text-white text-sm leading-4">{description}</div>
    </div>
  </div>
)
