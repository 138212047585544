import React, { useMemo } from 'react'

import cardBG from '../assets/images/mywic-swoosh-bg.jpg'
import phones from '../assets/images/mywic-phones.png'
import clinic from '../assets/images/wic-clinic.jpg'
import dynamicsLogo from '../assets/images/dynamics-logo.svg'
import myWICLogo from '../assets/images/mywic_logo_wht.svg'
import arrowUp from '../assets/images/icons/arrow-up.png'
import arrowDown from '../assets/images/icons/arrow-down.png'
import cert from '../assets/images/icons/cert.png'
import clock from '../assets/images/icons/clock.png'

import tw, { styled } from 'twin.macro'
import { StatCard } from '../components/StatCard'

const Card = styled.div`
  ${tw`rounded-lg flex-1 px-4 py-8 flex flex-col items-center`}
`

const MyWICCard = styled(Card)`
   background: url(${cardBG}) no-repeat top center;
   background-size: cover;
`

const DynamicsCard = styled(Card)`
   background: #fff url(${clinic}) no-repeat center bottom;
   background-size: cover;
`

export const Welcome = () => (
  <section id="welcome" data-scrollspy className="sm:mt-[-20px] mb-4">
    <div className="flex flex-col space-y-4 md:space-y-0 md:space-x-4 px-2 md:flex-row">
      <MyWICCard className="!pb-0">
        <h2 className="text-[20px] text-white font-bold text-center mb-6 leading-[23px]">
          A WIC experience designed and built
          {' '}
          <br className="hidden sm:block" />
          {' '}
          to put participant's needs first.
        </h2>
        <img src={myWICLogo} alt="MyWIC" className="h-6 mb-2" />
        <img src={phones} alt="phones" />
      </MyWICCard>
      <DynamicsCard className="min-h-[400px] md:min-h-auto">
        <h2 className="text-purple text-[18px] font-bold text-center leading-[22px] mb-6 mt-5">
            Redefining the clinic experience
          <br />
          <span className="text-gradient">
            An easy and fast solution enabling
            {' '}
            <br />
            {' '}
            clinic staff to focus on what matters.
          </span>
        </h2>
        <span className="text-xs text-magenta font-bold mb-1">Built on</span>
        <img src={dynamicsLogo} alt="Dynamics Logo" className="mb-6" />
        {/* <Button className="bg-blue text-white">Learn more</Button> */}
      </DynamicsCard>
    </div>
    <div className="px-2 pt-4">
      <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
        <StatCard stat="32m"
          icon={clock}
          description={
            <div className="text-center sm:text-left">
              Minutes saved per
              {' '}
              <br className="hidden sm:inline"/>
              {' '}
              appointment
            </div>
          }
          bgColor="bg-orange" />
        <StatCard stat="60%"
          icon={arrowDown}
          description={
            <div className="text-center sm:text-left">
              Decrease in
              {' '}
              <br className="hidden sm:inline"/>
              {' '}
              admin tasks
            </div>
          }
          bgColor="bg-magenta" />
        <StatCard stat="20%"
          icon={cert}
          description={
            <div className="text-center sm:text-left">
              Certifications initiated prior
              {' '}
              <br className="hidden sm:inline"/>
              {' '}
              to appointment (myWIC)
            </div>
          }
          bgColor="bg-berry" />
        <StatCard stat="30%"
          icon={arrowUp}
          description={
            <div className="text-center sm:text-left">
              Increase in new families
              {' '}
              <br className="hidden sm:inline"/>
              {' '}
              participation (myWIC)
            </div>
          }
          bgColor="bg-magenta-light" />
      </div>
    </div>
  </section>
)
