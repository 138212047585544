import './App.css';
import { WithNavMenu } from './components/Nav'
import { StatCard } from './components/StatCard';
import { Contact } from './sections/Contact';
import { Dynamics } from './sections/Dynamics';
import { MyWIC } from './sections/MyWIC';
import { UserAdoption } from './sections/UserAdoption';
import { Welcome } from './sections/Welcome';
import { appInsights } from './appInsights';
import { EndToEnd } from './sections/EndToEnd';
import purpleLogo from './assets/images/footer_logo.png'
import { Hero } from './components/Hero';

appInsights.trackPageView();

function App() {
  return (
    <div>
      <WithNavMenu>
        <Hero />
        <div className="max-w-[1500px] container mx-auto">
          <div className="relative z-[10] lg:px-24" id="mainContent">
            <Welcome />
            <MyWIC />
            <Dynamics />
            <EndToEnd />
            <div className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4 px-2 mt-4">
              <UserAdoption />
              <Contact />
            </div>
            <div className="text-right pb-12 px-2 mt-3 flex flex-row justify-between">
              <img src={purpleLogo} alt="WIC Mosaic Logo" className="h-7" />
              <p className="text-black text-xs">
                &copy;
                {' '}
                {new Date().getFullYear()}
                {' '}
                WIC MOSAIC
                <br/>
                All Rights Reserved
              </p>
            </div>
          </div>
        </div>
      </WithNavMenu>
    </div>
  );
}

export default App;
