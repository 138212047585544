import React, { useMemo } from 'react';

import selfService from '../assets/images/icons/self_service_reg.svg'
import cloudBased from '../assets/images/icons/cloud_based.svg'
import managed from '../assets/images/icons/managed.svg'
import workFlexibility from '../assets/images/icons/work_flexibility.svg'
import reporting from '../assets/images/icons/reporting.svg'
import adoption from '../assets/images/icons/adoption_support.svg'
import IconListItem from '../components/IconListItem'

export const EndToEnd = () => {
  
  const iconData1 = useMemo(() => [
    {
      icon: selfService,
      title: 'myWIC - A Participant Mobile App',
      subtitle: 'myWIC enhances participant experience by bringing WIC services to mom\'s fingertips',
    },
    {
      icon: cloudBased,
      title: 'Cloud Based',
      subtitle: 'Highly-configurable solution built on Microsoft Dynamics 365',
    },
    {
      icon: managed,
      title: 'Managed Service',
      subtitle: 'Enhanced features through leading-edge development principles',
    }
  ], [])

  const iconData2 = useMemo(() => [
    {
      icon: workFlexibility,
      title: 'Work Flexibility',
      subtitle: 'Whether working from a clinic or remote, MOSAIC allows for seamless, web-based performance',
    },
    {
      icon: reporting,
      title: 'Reporting & Insights',
      subtitle: 'Fast, centralized analytics and visualizations using Microsoft Power BI',
    },
    {
      icon: adoption,
      title: 'Adoption & Support',
      subtitle: 'Our WIC experts work with you every step of the way',
    }
  ], [])

  
  return (
    <div className="px-2">
      <div className="bg-tan rounded-lg p-6 sm:p-10 flex-1 mt-4">
        <div className="flex flex-col md:flex-row md:space-x-6 items-center">
          <div className="flex-1 mb-8 md:mb-0">
            <h2 className="text-purple text-lg leading-6 md:leading-auto sm:text-[24px] sm:leading-7 font-bold mb-3">
              A truly end-to-end solution created for WIC staff and participants from the ground up.
            </h2>
            <p className="text-gradient text-lg font-bold">
              Built with input from WIC staff allowing them to focus more on what matters.
              Designed with a focus on participants day-to-day allowing mom to take control
              of her WIC experience.
            </p>
          </div>
          <div className="flex-1 flex flex-col space-y-10 mb-12 md:mb-0">
            {iconData1.map(i => <IconListItem key={i.title} {...i} />)}
          </div>
          <div className="flex-1 flex flex-col space-y-10">
            {iconData2.map(i => <IconListItem key={i.title} {...i} />)}
          </div>
        </div>
      </div>
    </div>
  );
}