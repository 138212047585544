import React from 'react'
import dynamics from '../assets/images/dynamics-logo.svg'
import reporting from '../assets/images/report.png'
import crm from '../assets/images/crm.png'

export const Dynamics = () => (
  <section id="dynamics" data-scrollspy className="flex flex-col space-y-4 px-2 mt-4">
    <div className="rounded-lg bg-tan px-6 pt-6 lg:pt-0 lg:px-12 pr-0 lg:pr-0 flex-1 flex items-center flex-col lg:flex-row lg:space-x-4 opacity-95">
      <div className="flex-[4]">
        <h2 className="text-purple text-xl lg:text-[24px] lg:leading-7  font-bold mb-4 pr-6 sm:pr-0">
            Microsoft Dynamics 365 provides modern features that improve clerical and 
            clinical processes that enable staff to focus on what matters most. 
        </h2>
        <h3 className="text-gradient text-xl font-bold mb-2 mt-4 sm:mt-0">
          Key features:
        </h3>
        <ul className="text-purple text-sm font-bold list-disc list-inside">
          <li>Prescreen uploaded documents</li> 
          <li>Real-time inventory management</li>
          <li>In-app document signature</li>
          <li>Resource scheduling and availability</li>
          <li>Certification lifecycle</li>
          <li>Food package modification</li>
          <li>Manage current and future benefits</li>
          <li>Transfer families and participants</li>
          <li>Integrated Views and dashboards</li>
        </ul>
      </div>
      <div className="lg:pl-4 flex flex-col items-center mt-6 lg:mt-0">
        <span className="text-xs text-magenta font-bold mb-2">Built on</span>
        <img src={dynamics} alt="Dynamics" />
      </div>
      <div className="flex-[7]">
        <img src={crm} alt="CRM" className="h-full w-full" />
      </div>
    </div>
    <div className="rounded-lg bg-gray px-6 pt-6 lg:pt-0 lg:px-12 pr-0 lg:pr-0 flex-1 flex items-center flex-col lg:flex-row lg:space-x-4 opacity-95">
      <div className="flex-[4]">
        <h2 className="text-purple text-xl lg:text-[24px] lg:leading-7  font-bold mb-4 pr-6 sm:pr-0">
        Microsoft Power BI is at the center of the MOSAIC solution, allowing Program, Local Agency, 
        and Clinic staff the capability to transform their data into actionable insights. 
        </h2>
        <h3 className="text-gradient text-xl font-bold mb-3 mt-4 sm:mt-0">
          Key insights:
        </h3>
        <div className="flex space-x-3">
          <div className="flex-1">
            <p className="text-purple text-sm font-bold">Certification</p>
            <ul className="text-purple text-sm list-disc list-inside mb-4">
              <li>Timely recertifications</li> 
              <li>Expiration</li>
            </ul>

            <p className="text-purple text-sm font-bold">Retention</p>
            <ul className="text-purple text-sm list-disc list-inside">
              <li>Provisional conversion</li> 
              <li>Eligibility</li>
            </ul>
          </div>
          <div className="flex-1">
            <p className="text-purple text-sm font-bold">Participation</p>
            <ul className="text-purple text-sm list-disc list-inside mb-4">
              <li>Month comparisons</li> 
              <li>Daily trends</li>
            </ul>

            <p className="text-purple text-sm font-bold">Breastfeeding</p>
            <ul className="text-purple text-sm list-disc list-inside">
              <li>Duration</li> 
              <li>Exclusivity</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center mt-6 lg:mt-0">
        <span className="text-xs text-magenta font-bold mb-2">Built on</span>
        <img src={dynamics} alt="Dynamics" />
      </div>
      <div className="flex-[7]">
        <img src={reporting} alt="Report" className="h-full w-full" />
      </div>
    </div>
  </section>
)
