import React, { useMemo } from 'react'
import ReactPlayer from 'react-player/lazy'
import Modal from 'react-modal'
import myWICLogo from '../assets/images/mywic_logo_wht.svg'
import { Button } from '../components/Button'
import phone from '../assets/images/mywic-home.png'
import { appInsights } from '../appInsights'
import { isMobile } from 'react-device-detect';
import { myWicVideoURL } from '../utils'

const customStyles = {
  content: {
    top: '100px',
    left: '20%',
    right: '20%',
    bottom: '20%',
    padding: 0,
  },
};

export const MyWIC = () => {

  const keyFeatures = [
    { title: 'Self-Service Registration' },
    { title: 'Initial Certification' },
    { title: 'Real-time appointment management' },
    { title: 'Current and future benefits' },
    { title: 'Recertification' },
    { title: 'Document Upload' },
    { title: 'Push Notifications' },
    { title: 'Find a store/clinic' },
    { title: 'Built in shopping experience' },
    { title: 'And more...' }
  ];

  const [modalIsOpen, setModalIsOpen] = React.useState(false)

  return (
    <section id="myWIC" data-scrollspy className="flex px-2">
      <div className="rounded-lg bg-purple woman-bg p-6 sm:p-12 flex-1 flex flex-col md:flex-row md:space-x-12">
        <div className="sm:w-[280px]">
          <img src={myWICLogo} alt="MyWIC" className="h-10 mb-4 mx-auto" />
          <h2 className="text-xl md:text-[22px] md:leading-7 text-white font-bold mb-2">
            myWIC Mosaic is empowering participants and simplifying the clinic day-to-day. 
          </h2>
          <p className="text-gradient text-xl font-bold mt-5 mb-2">
            Key features
          </p>
          <ul className="list-disc list-inside mb-5">
            { keyFeatures.map(i => 
              <li key={i.title} className="text-white text-sm">{i.title}</li>) 
            }
          </ul>
          <div className="text-center">
            <Button onClick={() => {
              appInsights.trackEvent({ name: 'Clicked Watch myWIC Video (myWIC)' }, {});
              if (isMobile) {
                window.location.href = myWicVideoURL;
              }
              else {
                setModalIsOpen(true)
              }
            }}>
            Watch the video
            </Button>          
          </div>
        </div>
        <div className="flex flex-1 justify-center sm:justify-start pt-6 sm:pt-0 mb-[-24px] sm:mb-auto">
          <div className="h-[250px] overflow-y-hidden sm:h-auto sm:overflow-y-auto sm:pl-10">
            <img className="max-h-[500px]" src={phone} alt="MyWIC" />
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => setModalIsOpen(false)}
      >
        <ReactPlayer
          height="100%"
          width="100%"
          controls 
          muted={false}
          url={myWicVideoURL}
        />
      </Modal>
    </section>
  )
}