import Scroll from 'react-scroll'
import bgFallBack from '../assets/images/bg-fallback.png';
import arrowDown from '../assets/images/circle-arrow-down.png';

/* Temporary until we figure out why the mp4 won't deploy with the rest of the assets */
const bgVideo = 'https://wicmosaic.blob.core.windows.net/public/shared/videos/wicmosaic-bg.mp4';
const scroll = Scroll.animateScroll;

export const Hero = () => (
  <div className="mt-[60px] sm:mt-0 sm:relative sm:top-0 w-full z-0">
    <div className="py-8 sm:py-0 sm:absolute sm:top-[42%] w-full">
      <div className="max-w-[1500px] container mx-auto">
        <div className="px-12 sm:px-2 lg:px-24">
          <h1 className="text-[18px] text-center sm:text-left md:text-2xl lg:text-3xl xl:text-[40px] xl:leading-[46px] font-bold text-purple sm:text-white sm:max-w-[55%]">
            A modern solution for the
            {' '}
            <br className="sm:block" />
            <span className="text-gradient">Women, Infants, and Children Program</span>
            {' '}
            impacting participation, eligibility & retention.
          </h1>
        </div>
      </div>
    </div>
    <div className="hidden sm:block absolute bottom-8 left-[50%] translate-x-[-50%] z-10">
      <button 
        id="scrollDown"
        className="cursor-pointer"
        onClick={e => {
          e.preventDefault();
          const targetSection = document.querySelector(`#mainContent`);
          scroll.scrollTo(targetSection.offsetTop - 50);
        }}>
        <img src={arrowDown} alt="down arrow" />
      </button>
    </div>
    <video className="hidden sm:block w-full z-[-1] max-h-[100vh]" autoPlay loop muted id="bgVideo" poster={bgFallBack}>
      <source src={bgVideo} type="video/mp4" />
    </video>
  </div>
);