import React from 'react'

export const UserAdoption = () => (
  <section id="adoption" data-scrollspy className="flex flex-col space-y-4 flex-[7]">
    <div className="flex flex-col lg:flex-row lg:space-x-12 flex-1 bg-[#F7F7FB] opacity-95 p-6 lg:p-12 lg:items-center rounded-lg">
      <div className="flex-[3]">
        <div className="lg:pr-8">
          <h2 className="text-purple text-xl lg:text-[24px] lg:leading-7 font-bold mb-4 pr-6 sm:pr-0">
          User adoption and support at the core.
          </h2>
          <h3 className="text-stone-500 text-lg font-bold mb-2 mt-4 sm:mt-0">
          With years of WIC experience, our team consists of highly skilled customer support agents 
          and adoption specialists who are here to make sure staff is ready, using, and loving MOSAIC. 
          </h3>
        </div>
      </div>
      <div className="flex-[2]">
        <div className="lg:pl-4">
          <h3 className="text-gradient text-xl font-bold mb-2 mt-4 sm:mt-0">
          We provide:
          </h3>
          <ul className="text-black text-sm font-bold ">
            <li>Change management</li>
            <li>SME engagement</li>
            <li>Go-live readiness</li>
            <li>End user training</li>
            <li>Live support and assistance</li>
            <li>200+ knowledge base articles</li>
            <li>More...</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
)
