import React, { useState } from 'react'
import tw, { styled } from 'twin.macro';
import logo from '../assets/images/header_logo.svg'
import Scroll from 'react-scroll'
import { appInsights } from '../appInsights';

const scroll = Scroll.animateScroll;

const MenuItem = styled.a`
  ${tw`py-4 px-4 font-bold block relative opacity-90`}
  background: linear-gradient(122.18deg, #DF8564 23.66%, #B94787 91.92%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text; 
  transition: 1s;

  &.active {
    ${tw`text-black`}
    background: none;
    -webkit-background-clip: initial;
    -webkit-text-fill-color: initial;
    background-clip: initial;

    :after {
      content: "\\25CF";
      position: absolute;
      top: 2em;
      left: 50%;
      width: 10px;
      margin-left: -5px;
      color: #DF8564;
    }
  }
`

// Abstracted from ScrollSpy to allow for easier customizations
const onScrollUpdate = (entry, isInVewPort) => {
  const { target, boundingClientRect } = entry;
  const menuItem = document.querySelector(`[data-scrollspy-id="${target.id}"]`);
  if (boundingClientRect.y <= 0 && isInVewPort) {
    menuItem.classList.add("active");
  } else {
    if (menuItem.classList.contains("active")) {
      menuItem.classList.remove("active");
    }
  }
};

const NavMenu = () => {
  
  const trackPageView = (hash) => {

    let pageName = ''
    switch (hash) {
      case '#welcome':
        pageName = 'Welcome'
        break;
      case '#myWIC':
        pageName = 'MyWIC'
        break;
      case '#dynamics':
        pageName = 'Dynamics 365'
        break;
      case '#adption':
        pageName = 'User Adoption'
        break;
      case '#contact':
        pageName = 'Contact'
        break;
      default:
        pageName = 'WIC Mosaic'
        break;
    }

    appInsights.trackPageView({
      name: pageName
    });
  }

  const onClick = (e) => {
    e.preventDefault();

    setSelectedItem(e.target.hash.replace('#', ''));

    const targetSection = document.querySelector(`${e.target.hash}`);
    scroll.scrollTo(targetSection.offsetTop - 50);

    trackPageView(e.target.hash);
    window.location.hash = e.target.hash;
  };

  const mobileClick = (e) => {
    e.preventDefault();

    setShowMenu(false)

    
    const targetSection = document.querySelector(`${e.target.hash}`);
    console.log(targetSection)
    scroll.scrollTo(targetSection.offsetTop - 50);
    
    trackPageView(e.target.hash);
    window.location.hash = e.target.hash;
  }

  const [showMenu, setShowMenu] = useState(false)
  const [selectedItem, setSelectedItem] = useState('welcome')

  return (
    <nav className="bg-white shadow-sm fixed w-full opacity-95 z-20">
      <div className="max-w-6xl mx-auto px-4">
        <div className="flex justify-between">
          <div className="flex flex-1 items-center">
            <div className="md:hidden flex items-center">
              <button className="outline-none mobile-menu-button" onClick={() => setShowMenu(!showMenu)}>
                <svg className="w-6 h-6 text-magenta"
                  x-show="!showMenu"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M4 6h16M4 12h16M4 18h16"></path>
                </svg>
              </button>
            </div>
            <div className="flex flex-1 md:flex-none py-3 justify-center">
              <a href="#home" className="sm:px-2">
                {/* <img src="logo.png" alt="Logo" className="h-8 w-8 mr-2" /> */}
                <img src={logo} alt="WIC Logo" className="h-9 sm:h-7 -ml-4 sm:ml-0" />
              </a>
            </div>
            <div className="hidden md:flex flex-1 items-center justify-center space-x-1">
              {/* <MenuItem onClick={onClick} href="#welcome" data-scrollspy-id="welcome">Welcome</MenuItem>
              <MenuItem onClick={onClick} href="#myWIC" data-scrollspy-id="myWIC">myWIC</MenuItem>
              <MenuItem onClick={onClick} href="#dynamics" data-scrollspy-id="dynamics">Built on Dynamics 365</MenuItem>
              <MenuItem onClick={onClick} href="#adoption" data-scrollspy-id="adoption">User Adoption</MenuItem>
              <MenuItem onClick={onClick} href="#contact" data-scrollspy-id="contact">Contact Us</MenuItem> */}
              
              <MenuItem onClick={onClick} href="#welcome" className={selectedItem === 'welcome' && 'active'}>Welcome</MenuItem>
              <MenuItem onClick={onClick} href="#myWIC" className={selectedItem === 'myWIC' && 'active'}>myWIC</MenuItem>
              <MenuItem onClick={onClick} href="#dynamics" className={selectedItem === 'dynamics' && 'active'}>Built on Dynamics 365</MenuItem>
              <MenuItem onClick={onClick} href="#adoption" className={selectedItem === 'adoption' && 'active'}>User Adoption</MenuItem>
              <MenuItem onClick={onClick} href="#contact" className={selectedItem === 'contact' && 'active'}>Contact Us</MenuItem>
            </div>
          </div>
          
        </div>
      </div>
      <div className={`flex flex-col -space-y-3 ${!showMenu ? 'hidden' : ''} md:hidden`}>
        <MenuItem href="#welcome" onClick={mobileClick}>Welcome</MenuItem>
        <MenuItem href="#myWIC" onClick={mobileClick}>myWIC</MenuItem>
        <MenuItem href="#dynamics" onClick={mobileClick}>Built on Dynamics 365</MenuItem>
        <MenuItem href="#adoption" onClick={mobileClick}>User Adoption</MenuItem>
        <MenuItem href="#contact" onClick={mobileClick}>Contact Us</MenuItem>
      </div>
    </nav>
  );
};

export const WithNavMenu = ({ children, selector }) => (
  <div className="flex">
    <div className="relative">
      <NavMenu />
    </div>
    <div className="flex-1">{children}</div>
  </div>
);
