import { ApplicationInsights } from '@microsoft/applicationinsights-web'

const appInsights = new ApplicationInsights(
  { 
    config: {
      connectionString: 'InstrumentationKey=2f09b6da-b031-4dcc-a324-74d68f163456;IngestionEndpoint=https://southcentralus-3.in.applicationinsights.azure.com/;LiveEndpoint=https://southcentralus.livediagnostics.monitor.azure.com/'
    } 
  }
);
appInsights.loadAppInsights();

export {
  appInsights
}